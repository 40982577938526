<template>
  <div class="w-full h-full flex flex-col flex-grow">
    <div class="flex h-full gap-5">
      <div class="flex flex-col h-full w-44 text-white" v-if="checkRoute">
        <dashboard-item-card :icon="'group_add'" :link="'ma-new-hire'">
        </dashboard-item-card>
        <dashboard-item-card :icon="'school'" :link="'ma-staff-training'">
        </dashboard-item-card>
        <dashboard-item-card
          :icon="'person_search'"
          :link="'ma-staff-induction'"
        >
        </dashboard-item-card>
        <dashboard-item-card :icon="'inventory_2'" :link="'ma-appraisals'">
        </dashboard-item-card>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import dashboardItemCard from "@/components/ui/baseComponents/itemCardDashboard.vue";
export default {
  components: { dashboardItemCard },
  data() {
    return {};
  },
  computed: {
    checkRoute() {
      if (
        this.$route.name === "ma-humanResources" ||
        this.$route.name === "ma-new-hire" ||
        this.$route.name === "ma-staff-training" ||
        this.$route.name === "ma-staff-induction" ||
        this.$route.name === "ma-appraisals"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
